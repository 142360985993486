<template>
  <div>
    <b-row class="no-gutters mb-3">
      <b-col>
        <div class="font-weight-bold ft-20 header-tablepage">Redeem Report</div>
      </b-col>
    </b-row>
    <div class="bg-white">
      <div class="pl-3 pr-3 pt-3">
        <b-input-group>
          <b-form-input
            class="search-bar"
            @keyup.enter="getRedeemOrderList()"
            placeholder="Search Name, Member ID, Tel."
            v-model="filter.search"
          >
          </b-form-input>
          <b-input-group-append is-text class="mr-2">
            <b-iconstack
              font-scale="2"
              type="submit"
              @click="getRedeemOrderList()"
            >
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
          <b-dropdown text="Options" class="main-color btn-filter mr-2" right>
            <b-dropdown-item
              @click="confirmAction(1)"
              :disabled="actionDisabled"
              ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
                >รอจัดเตรียมสินค้า</span
              ></b-dropdown-item
            >
            <b-dropdown-item
              @click="confirmAction(2)"
              :disabled="actionDisabled"
              ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
                >กำลังจัดเตรียม</span
              ></b-dropdown-item
            >
            <b-dropdown-item
              @click="confirmAction(3)"
              :disabled="actionDisabled"
              ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
                >สินค้าพร้อมรับ</span
              ></b-dropdown-item
            >
            <b-dropdown-item
              @click="confirmAction(4)"
              :disabled="actionDisabled"
              ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
                >รับของโดยไม่ได้รับ OTP</span
              ></b-dropdown-item
            >
            <b-dropdown-item
              @click="confirmAction(5)"
              :disabled="actionDisabled && !onlyAction"
              ><span
                :class="
                  actionDisabled && !onlyAction ? 'text-gray' : 'text-black'
                "
                >รับของแล้ว</span
              ></b-dropdown-item
            >
            <b-dropdown-item
              @click="confirmAction(6)"
              :disabled="actionDisabled"
              ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
                >ยกเลิก</span
              ></b-dropdown-item
            >
            <b-dropdown-item
              @click="confirmAction(7)"
              :disabled="actionDisabled"
              ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
                >หมดอายุ</span
              ></b-dropdown-item
            >
            <b-dropdown-item
              @click="confirmAction(8)"
              :disabled="actionDisabled"
              ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
                >ส่งสำเร็จ</span
              ></b-dropdown-item
            >
          </b-dropdown>
          <b-button @click="showFilter" class="btn button btn-mobile px-4">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="main-color mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline">Filter</span>
          </b-button>

          <button
            type="button"
            class="btn button btn-mobile px-4 ml-2"
            @click="exportData"
            :disabled="exportDisabled"
          >
            <font-awesome-icon icon="plus" class="text-black d-sm-none" />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="file-upload" class="pointer" />
              Export
            </span>
          </button>
          <button
            type="button"
            class="btn button btn-mobile px-4 ml-2"
            @click="importData"
          >
            <font-awesome-icon
              icon="file-import"
              class="text-black d-sm-none"
            />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="file-import" class="pointer" />
              Import
            </span>
          </button>
        </b-input-group>
      </div>
      <b-sidebar
        id="sidebar-filter"
        v-model="sidebarShow"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
        aria-labelledby="sidebar-filter-title"
        no-header
        width="85vw"
      >
        <div class="px-3">
          <b-row class="header-filter">
            <b-col class="text-filter">Filter</b-col>
            <b-col class="text-right">
              <button class="btn-clear" @click.prevent="clearFilter">
                x <span class="text-under">Clear Filter</span>
              </button>
            </b-col>
          </b-row>

          <template>
            <FilterCustomDate
              id="redeem-date"
              customText="Redeem Date"
              :from="filter.redeem_date_from"
              :to="filter.redeem_date_to"
              :type="filter.LastType"
              @update:type="(value) => (filter.LastType = value)"
              @update:from="(value) => (filter.redeem_date_from = value)"
              @update:to="(value) => (filter.redeem_date_to = value)"
              :useStore="false"
            />

            <div>
              <MultiSelect
                textFloat="Pick up"
                :options="[
                  { id: 1, name: 'Pick up at branch' },
                  { id: 2, name: 'Pick up at home' },
                  { id: 3, name: 'Nothing' },
                ]"
                textField="name"
                valueField="id"
                placeholder="Select Pickup Type"
                v-model="filter.pick_up_type"
                :value="filter.pick_up_type"
              />
              <MultiSelect
                textFloat="Branch"
                :options="branchListOption"
                textField="name"
                valueField="id"
                placeholder="Select Branch"
                v-model="filter.branch_list_id"
                :value="filter.branch_list_id"
              />

              <MultiSelect
                textFloat="Redeem Name"
                :options="redeemItemOptions.redeem"
                textField="name"
                valueField="id"
                placeholder="Select Redeem Name"
                v-model="filter.list_redeem_id"
                :value="filter.list_redeem_id"
                @input="selectRedeem"
              />

              <MultiSelect
                textFloat="Redeem Item"
                :options="redeemItemOptions.redeem_item"
                textField="name"
                valueField="id"
                placeholder="Select Redeem Item"
                v-model="filter.redeem_item"
                :value="filter.redeem_item"
                :disable="redeemItemOptions.redeem_item.length == 0"
              />
            </div>
          </template>
        </div>
        <template #footer>
          <div class="d-flex justify-content-between shadow align-items-center">
            <div class="w-100">
              <b-button
                block
                variant="dark"
                class="text-light rounded-pill"
                @click.prevent="hide"
              >
                Cancel
              </b-button>
            </div>
            <div class="w-100">
              <b-button
                @click.prevent="submitFilter"
                block
                variant="light"
                id="handle-search-filter"
                class="text-body rounded-pill btn-main"
              >
                Search
              </b-button>
            </div>
          </div>
        </template>
      </b-sidebar>
      <div class="p-3">
        <b-tabs v-model="tabIndex">
          <template v-for="items of statusListOption">
            <b-tab
              :key="'tab-' + items.status"
              :title="`${items.name} (${items.total})`"
              class="text-nowrap"
            ></b-tab>
          </template>
        </b-tabs>

        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="reportList"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:head(redeem_order_id)="">
            <b-form-group class="cb-table">
              <b-form-checkbox
                v-model="selectAllProduct"
                @change="selectAllTable"
                :unchecked-value="0"
                :value="1"
              ></b-form-checkbox>
            </b-form-group>
          </template>
          <template v-slot:cell(telephone)="data">
            <div>{{ convertTelePhone(data.item.telephone) }}</div>
          </template>
          <template v-slot:cell(redeem_order_id)="data">
            <b-form-group class="cb-table">
              <b-form-checkbox
                :value="data.item.redeem_order_id"
                v-model="action.list_redeem_order"
                @change="selectProductTable"
              ></b-form-checkbox>
            </b-form-group>
          </template>
          <template v-slot:cell(name)="data">
            <router-link
              :to="'/report/customer/' + data.item.user_GUID"
              class="text-underline"
            >
              <!-- <b-button variant="link" class="name-link"> -->
              {{ data.item.name }}
              <!-- </b-button> -->
            </router-link>
            <div class="text-gray">
              {{ data.item.member_id }}
            </div>
            <div class="text-gray">
              {{ data.item.telephone }}
            </div>
          </template>

          <template v-slot:cell(branch_name)="data">
            <div v-if="pickUpType == 2">{{ address(data.item) }}</div>
            <div v-else-if="pickUpType == 3">-</div>
            <div v-else>{{ data.item.branch_name || "-" }}</div>
          </template>
          <template v-slot:cell(redeem_date)="data">
            <p class="m-0" v-if="data.item.status_name">
              <span v-if="data.item.status < 3" class="text-warning">
                {{ data.item.status_name }}
              </span>
              <span
                v-else-if="data.item.status < 6 || data.item.status == 8"
                class="text-success"
              >
                {{ data.item.status_name }}
              </span>
              <span v-else class="text-error">
                {{ data.item.status_name }}
              </span>
            </p>
            <p v-else class="m-0">-</p>
            <div class="text-gray">
              <span v-if="data.item.pick_up_type_id == 1">
                ({{ data.item.branch_name || "-" }})
              </span>
              <span v-else-if="data.item.pick_up_type_id == 2">
                (Pick up at home)
              </span>
              <span v-else> - </span>
            </div>
            <div class="text-gray">
              ({{
                new Date(data.item.redeem_date) | moment("DD MMM YYYY HH:MM")
              }})
            </div>
          </template>
          <template v-slot:cell(received_date)="data">
            <p
              class="m-0"
              v-if="
                data.item.received_date &&
                data.item.received_date != '0001-01-01T00:00:00'
              "
            >
              <span
                >{{
                  new Date(data.item.received_date) | moment($formatDate)
                }}
                ({{
                  new Date(data.item.received_date) | moment($formatTime)
                }})</span
              >
            </p>
            <p v-else class="m-0">-</p>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col cols="12" md="6" class="form-inline justify-content-start">
            <p class="mb-0 p-gray">
              Showing {{ rows > 0 ? filter.start + 1 : 0 }} to
              {{
                filter.start + filter.length >= rows
                  ? rows
                  : filter.start + filter.length
              }}
              of {{ rows }} entries
            </p>
          </b-col>
          <b-col cols="12" md="6" class="form-inline justify-content-end">
            <div>
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.length"
                @change="pagination"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="m-md-0"
              ></b-pagination>
            </div>
            <div class="ml-2">
              <b-form-select
                v-model="filter.length"
                @change="handlePerpage"
                :options="pageOptions"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
      </div>
      <ModalImportFile
        ref="modalImportFile"
        :id="0"
        redeemReport
        @success="successImport"
      />
      <ModalUpdateNote
        ref="modalUpdateNote"
        :form="filterNote"
        :v="$v"
        v-if="!loadingModalNote"
        @updateNote="updateNote"
      />
    </div>
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import OtherLoading from "@/components/loading/OtherLoading";
import ModalUpdateNote from "@/components/redeem/modal/ModalUpdateNote";
import ModalImportFile from "@/components/redeem/modal/ModalImportFile";
import { required } from "vuelidate/lib/validators";
export default {
  name: "RedeemReport",
  components: {
    InputSelect,
    OtherLoading,
    ModalUpdateNote,
    ModalImportFile,
  },

  validations: {
    filterNote: {
      note: { required },
    },
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      totalRedeem: 19954,
      received: 12780,
      waitForReceived: 9146,
      cancel: 258,
      sidebarShow: false,
      filter: {
        page: 1,
        start: 0,
        length: 10,
        search: "",
        redeemOrderStatus: 0,
        redeem_item: [], // list int
        list_redeem_id: [], // list int
        redeem_date_from: this.$moment().format(),
        redeem_date_to: this.$moment().format(),
        LastType: 3, // 1 = this weeks, 2 = this month, 3 = this year, 0 = custom
        branch_list_id: [], // list int

        pick_up_type: [],
      },
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      branchListOption: [],
      statusListOption: [
        { status: 0, total: 0, name: "All" },
        { status: 1, total: 0, name: "รอจัดเตรียมสินค้า" },
        { status: 2, total: 0, name: "กำลังจัดเตรียม" },
        { status: 3, total: 0, name: "สินค้าพร้อมรับ" },
        { status: 4, total: 0, name: "รับของโดยไม่ได้รับ OTP" },
        { status: 5, total: 0, name: "รับของแล้ว" },
        { status: 6, total: 0, name: "ยกเลิก" },
        { status: 7, total: 0, name: "หมดอายุ" },
        { status: 8, total: 0, name: "ส่งสำเร็จ" },
      ],
      fields: [
        {
          key: "redeem_order_id",
          label: "",
          thClass: "text-nowrap",
          class: "w-1",
        },
        {
          key: "name",
          label: "Customer",
          thClass: "text-nowrap",
          class: "text-left w-2",
        },
        {
          key: "redeem_name",
          label: "Redeem Name",
          thClass: "text-nowrap",
          class: "w-2",
        },
        {
          key: "gift",
          label: "Redeem Item",
          thClass: "text-nowrap",
          class: "w-2",
        },
        {
          key: "used_point",
          label: "Used Point",
          thClass: "text-nowrap",
          class: "w-1",
        },

        {
          key: "redeem_date",
          label: "Redeem Date",
          thClass: "text-nowrap",
          class: "w-2",
        },
      ],
      reportList: [],
      ListIdAll: [],
      ListAll: [],
      selectAllProduct: 0,
      selectItem: [],
      action: {
        list_redeem_order: [],
        to_status: 0,
        redeem_id: 0,
      },
      redeemItemOptions: {
        redeem: [],
        redeem_item: [],
      },
      actionDisabled: true,
      onlyAction: true,
      filterNote: {
        redeem_order_id: 0,
        note: null,
      },
      loadingModalNote: true,
      pickUpType: "",
      tabIndex: 0,
    };
  },
  watch: {
    tabIndex(val) {
      this.filter.page = 1;
      this.selectAllProduct = false;
      this.action.list_redeem_order = [];
      this.getRedeemOrderList(val);
    },
  },
  computed: {
    textStatusClass(status) {
      if (status < 3) {
        return "text-warning";
      } else if (status < 6) {
        return "text-success";
      } else {
        return "text-error";
      }
    },
    exportDisabled() {
      return this.action.list_redeem_order.length > 0 ? false : true;
    },
  },
  created() {
    // this.getBranchList();
    this.getRedeemOrderList();
  },
  methods: {
    async getRedeemOrderList(val = 0) {
      this.isBusy = true;
      this.filter.start = (this.filter.page - 1) * this.filter.length;
      this.filter.redeemOrderStatus = val;
      const resp = await this.axios.post(
        "/redeem/statusdetail/AllRedeem",
        this.filter
      );
      const data = resp.data;

      if (data.result == 1) {
        this.reportList = data.detail.data.redeemList;
        this.ListAll = data.detail.redeemListOrderId;
        this.ListIdAll = data.detail.redeemListOrderId.map((el) => el.id);
        this.rows = data.detail.redeemListTotal;
        this.statusListOption = data.detail.redeemStatusCount.sort(
          (a, b) => a.status - b.status
        );
      } else {
        this.reportList = [];
        this.ListIdAll = [];
        this.rows = 0;
      }
      this.isBusy = false;
    },
    async getBranchList() {
      if (this.branchListOption.length > 0) return;
      await this.$store.dispatch("getBranchList", null);
      const data = this.$store.state.redeem.branchList;
      if (data.result == 1) {
        this.branchListOption = this.branchListOption.concat(data.detail);
      }
    },
    async exportData() {
      this.isLoading = true;
      let payload = { ...this.filter };
      payload.redeem_order_id = this.selectAllProduct
        ? []
        : this.action.list_redeem_order;
      payload.is_all = this.selectAllProduct;
      const data = await this.axios.post(
        "/redeem/ExportByRedeemOrderIdList",
        payload,
        {
          responseType: "blob",
        }
      );

      var fileURL = window.URL.createObjectURL(new Blob([data.data])); // change object.
      var fileLink = document.createElement("a");
      var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `RedeemOrderList-` + dateExcel + `.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();

      this.isLoading = false;
    },

    selectAllTable(value) {
      if (value) {
        this.action.list_redeem_order = this.ListIdAll;
      } else {
        this.action.list_redeem_order = [];
      }
      this.checkDisabledAction();
    },
    checkDisabledAction() {
      this.actionDisabled = false;
      this.onlyAction = false;

      if (this.action.list_redeem_order.length > 0) {
        this.action.list_redeem_order.forEach((e) => {
          this.ListAll.forEach((report) => {
            if (report.id == e) {
              if (report.status > 3) {
                this.actionDisabled = true;
              }
              if (report.status == 4) this.onlyAction = true;
            }
          });
        });
      } else {
        this.onlyAction = false;
        this.actionDisabled = false;
      }
    },
    confirmAction(status) {
      this.action.to_status = status;
      this.changeStatus();
    },
    selectProductTable(value) {
      this.checkDisabledAction();
      this.selectAllProduct = this.ListIdAll.length == value.length ? 1 : 0;
    },
    async changeStatus() {
      this.isBusy = true;

      await this.$store.dispatch("updateStatusRedeem", this.action);
      const data = this.$store.state.redeem.updateStatusRedeem;
      if (data.result == 1) {
        this.isBusy = false;
        this.selectAllProduct = 0;
        this.action.list_redeem_order = [];
        this.actionDisabled = true;
        this.onlyAction = true;
        this.successAlert();
        await this.getRedeemOrderList(this.tabIndex);
      } else {
        this.errorAlert(data.message);
      }
    },
    pagination(page) {
      this.filter.page = page;
      this.getRedeemOrderList(this.tabIndex);
    },
    async handlePerpage(value) {
      this.filter.page = 1;
      this.filter.length = value;
      this.getRedeemOrderList(this.tabIndex);
    },
    async submitFilter() {
      this.filter.page = 1;
      this.action.list_redeem_order = [];
      this.selectAllProduct = 0;
      this.sidebarShow = false;
      await this.getRedeemOrderList(this.tabIndex);
    },
    hide() {
      this.sidebarShow = false;
    },
    clearFilter() {
      this.filter = {
        page: 1,
        start: 0,
        length: 10,
        search: "",
        redeemOrderStatus: 0,
        redeem_item: [], // list int
        list_redeem_id: [], // list int
        redeem_date_from: "",
        redeem_date_to: "",
        LastType: 3, // 1 = this weeks, 2 = this month, 3 = this year, 0 = custom
        branch_list_id: [], // list int
        pick_up_type: [],
      };
      // this.filter.page = 1;
      this.action.list_redeem_order = [];
      this.selectAllProduct = 0;
      this.getRedeemOrderList();
      this.$refs.filterSidebar.hide();
    },

    importData() {
      this.$refs.modalImportFile.show();
    },
    address(item) {
      let address = `${item.home_address || ""} ${item.subdistrict || ""} ${
        item.district || ""
      } ${item.province || ""} ${item.zip_code || ""}`;
      return address.trim() ? address : "-";
    },
    async getRedeemList() {
      const resp = await this.axios.post("/redeem/filter_redeem", this.filter);

      const { redeem } = resp.data.detail;
      this.redeemItemOptions.redeem = redeem;
    },
    async showFilter() {
      this.$bus.$emit("showLoading");
      await this.getBranchList();
      await this.getRedeemList();
      this.$bus.$emit("hideLoading");
      this.sidebarShow = true;
    },
    successImport() {
      this.$refs.modalImportFile.hide();
      this.getRedeemOrderList(this.tabIndex);
    },
    async selectRedeem(val) {
      let payload = {
        list_redeem_filter_id: val,
      };
      const resp = await this.axios.post("/redeem/filter_redeem_item", payload);
      if (!resp.data.detail) return (this.redeemItemOptions.redeem_item = []);
      const { redeem_item } = resp.data.detail;
      this.redeemItemOptions.redeem_item = redeem_item.map((el) => {
        return { ...el, name: `${el.name} (${el.redeem_name})` };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
label {
  font-weight: bold;
}
.pre-box {
  background: var(--theme-secondary-color) !important;
  color: var(--font-color) !important;
}

.cb-table {
  position: relative;
  margin-left: 15px;
  padding-top: 15px;
}
::v-deep .btn-secondary.dropdown-toggle {
  background-color: white;
  border-color: var(--primary-color);
}
.text-gray {
  color: gray;
}
// ::v-deep .tabs-button {
//   width: 200px;
// }
</style>
